import { apiClient } from "./api-client";
import axios from "./axiosInstance";

async function clientLogger(message, data = {}) {
	try {
		const timeStampFromClient = new Intl.DateTimeFormat("en-IN", {
			dateStyle: "full",
			timeStyle: "long",
			timeZone: "Asia/Kolkata",
		}).format(new Date());

		await apiClient.instance.post("/api/client-logs", {
			message,
			data: { source: "client", ...data, timeStampFromClient },
		});
	} catch (error) {
		console.log("error while logging");
		console.log(error.message);
	}
}

const getLogsForAnInterview = async (interviewReportId, nextForwardToken = "") => {
	try {
		if (interviewReportId) {
			const response = await axios.get(
				`/api/client-logs/${interviewReportId}?nextForwardToken=${nextForwardToken}`,
			);
			if (response?.data?.data) {
				return response.data.data;
			}
		} else {
			throw new Error("Invalid interviewReport ID");
		}
	} catch (e) {
		console.log("failed to fetch logs");
		const message = e?.response?.data?.message || e.message;
		const error = new Error(message);
		const name = e?.response?.data?.name;
		error.name = name;
		throw error;
	}
};

export { clientLogger, getLogsForAnInterview };
