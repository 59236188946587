import { Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import poster from "./../assets/zinterview-logo-black.png";
import { isMobile } from "react-device-detect";

const InterviewEnd = () => {
	const location = useLocation();
	const orgName = location?.state?.orgName;
	const userInterviewReportId = location?.state?.userInterviewReportId;

	useEffect(() => {
		console.log({ userInterviewReportId, orgName });
		if (userInterviewReportId && orgName && orgName.toLowerCase?.() === "zinterview.ai") {
			const reportUrl = `https://zjobs.ai/insights-report/${userInterviewReportId}`;
			setTimeout(() => {
				window.location.href = reportUrl;
			}, 5 * 1000);
		}
	}, [userInterviewReportId]);

	return (
		<Box className={`flex flex-col items-center justify-center min-h-screen bg-gray-100`}>
			<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/2 lg:w-1/2">
				<Typography
					variant="h4"
					className={`text-center font-bold mb-6 ${isMobile ? "text-2xl" : "text-4xl"}`}
				>
					Interview Completed
				</Typography>
				<Typography
					variant="h6"
					className={`text-justify font-medium ${isMobile ? "text-sm" : "text-lg"}`}
				>
					Thank you for taking the time to participate in our interview process. We appreciate your
					interest in joining our team. We hope you had a positive experience with our innovative AI
					interviewer. Your responses have been valuable, and we will carefully review them. Our HR
					team will reach out to you with the next steps soon. If you have any further questions,
					feel free to contact us.
					<br />
					Best regards,
					<br />
					{orgName ? orgName : "Acme Corp"}
				</Typography>
			</Box>
			<Box className="flex justify-center items-center pb-[20px] mt-8 ">
				<Typography variant="span" className="text-xs text-gray-500">
					Powered by{" "}
				</Typography>
				<img src={poster} alt="zinterview" className="h-[28px] object-contain" />
			</Box>
		</Box>
	);
};

export default InterviewEnd;
