import { useMemo, useState } from "react";
import { convertTimeZone, getFormattedDateTime4 } from "../../utilities/utilityFunctions";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import "flatpickr/dist/flatpickr.min.css";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Button,
	TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment-timezone";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { rescheduleInterviewByCandidate } from "../../utilities/interviewReportsApi";

export const InterviewRescheduler = ({ schedule, maxRescheduleDateForCandidate, interviewTimeZone }) => {
	const { interviewReportId } = useParams();
	const [updating, setUpdating] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date(schedule));

	const [selectedTimeZone, setSelectedTimeZone] = useState(() => {
		try {
			const timeZone = interviewTimeZone || moment.tz.guess();
			if (moment.tz.zone(timeZone)) {
				return timeZone;
			} else {
				return moment.tz.guess();
			}
		} catch {
			return moment.tz.guess();
		}
	});

	const minDate = new Date(schedule);
	const maxDate = new Date(maxRescheduleDateForCandidate);

	const timeZones = useMemo(() => {
		return moment.tz.names().map((tz) => {
			const offset = moment.tz(tz).utcOffset();
			const hours = Math.floor(offset / 60);
			const minutes = Math.abs(offset % 60);
			const sign = offset >= 0 ? "+" : "-";
			const label = `(UTC${sign}${Math.abs(hours).toString().padStart(2, "0")}:${minutes
				.toString()
				.padStart(2, "0")}) ${tz.replace("_", " ")}`;
			return {
				label,
				value: tz,
			};
		});
	}, []);

	const handleDateChange = (dates) => {
		if (!dates.length) return;

		try {
			const newSelectedDate = new Date(dates[0]);

			// Validate against min date
			if (newSelectedDate < minDate) {
				setSelectedDate(new Date(minDate));
				return;
			}

			// Validate against max date
			if (newSelectedDate > maxDate) {
				setSelectedDate(new Date(maxDate));
				return;
			}

			setSelectedDate(newSelectedDate);
		} catch (error) {
			console.error("Date selection error:", error);
			toast.error("Invalid date selection. Please try again.");
		}
	};

	const handleChange = (event, newValue) => {
		if (newValue) {
			setSelectedTimeZone(newValue.value);
			const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: newValue.value }));
			if (currentDate > selectedDate) setSelectedDate(currentDate);
		}
	};

	const onReschedule = async () => {
		try {
			setUpdating(true);
			const formData = {
				interviewReportId,
				schedule: convertTimeZone(
					dayjs(selectedDate).toISOString(),
					moment.tz.guess(),
					selectedTimeZone,
				),
				interviewTimeZone: selectedTimeZone,
				host: `${window.location.protocol}//${window.location.host}`,
			};
			const response = await rescheduleInterviewByCandidate(formData);
			toast.success(response?.message);
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} catch (error) {
			console.error("Error rescheduleInterviewByCandidate:", error);
			toast.warn(error.message);
		} finally {
			setUpdating(false);
		}
	};

	return (
		<Accordion sx={{ ":before": { backgroundColor: "unset" } }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<span>Reschedule Interview</span>
			</AccordionSummary>
			<AccordionDetails>
				<div>
					<div className="my-4 rounded-lg border border-gray-200 shadow-sm p-4">
						<Box>
							<Autocomplete
								className="mt-2"
								options={timeZones}
								getOptionLabel={(option) => option.label}
								value={timeZones.find((tz) => tz.value === selectedTimeZone) || null}
								onChange={handleChange}
								renderInput={(params) => (
									<TextField {...params} label="Choose Time Zone" variant="outlined" />
								)}
							/>
						</Box>
						<Flatpickr
							value={selectedDate}
							options={{
								enableTime: true,
								dateFormat: "D M d Y h:i K",
								minTime: "00:00",
								maxTime: "23:59",
								minDate,
								maxDate,
								inline: true,
								onChange: handleDateChange,
							}}
							style={{ display: "none" }}
						/>
						<div className="mt-2 text-sm text-gray-500">
							{`You can reschedule between ${getFormattedDateTime4(minDate)} and ${getFormattedDateTime4(maxDate)}`}
						</div>
					</div>
					<Button
						disabled={updating}
						variant="contained"
						className="block mx-auto"
						onClick={onReschedule}
					>
						Confirm
					</Button>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
