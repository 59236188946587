import { Button, Card, CardContent, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { TextAreaFormInput } from "./text-area-form-input";
import { useCreateNewCheatingScoreMutation } from "../../features/api/apiSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const EvaluateForm = ({ isMakingNewEvalRequest, onSubmit }) => {
	const { interviewReportId } = useParams();
	const showAntiCheatingScoreField = localStorage.getItem("acs") === "1";

	const methods = useForm({
		defaultValues: {
			customEvaluationPrompt: {
				questionScore: `Score that is awarded to the candidate's answer for the current question in percentage out of 100. Try to understand the context of the response and give score accordingly. If the candidate's answer lacks depth, don't give them high scores. The depth of the answer should be considered while awarding scores. If the answer lacks depth or is vague, give them a lower score accordingly. If an answer is not given, you can award 0.`,
				overallScore: `The overall score in percentage out of 100 that you would award to the candidate based on his/her interview performance for the given job role. For technical interviews, Behavioural questions should not be considered in the overall_score_in_percentage, only technical questions that assess the candidate's technical skills should be considered while calculating the overall score. Please be strict in your evaluation. Don't be lazy or in a hurry, take your time to go through the answers and then award the over all score.`,
				skillRating: `The rating you would award to the candidate for the corresponding skill, out of 5. Strict scrutiny must be made while awarding ratings for each skill. Make sure your ratings align with the candidates responses and his knowledge on the skill. This will give the recruiter and the employee an idea about the candidate's expertise in the current skill.`,
			},
		},
	});

	const { handleSubmit } = methods;

	return (
		<Card>
			<CardContent>
				<FormProvider {...methods}>
					<h1>Get Custom Evaluation</h1>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Stack>
							<TextAreaFormInput
								id="customEvaluationPrompt.questionScore"
								label="Question Score Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<TextAreaFormInput
								id="customEvaluationPrompt.overallScore"
								label="Overall Score Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<TextAreaFormInput
								id="customEvaluationPrompt.skillRating"
								label="Skill Rating Evaluation Prompt"
								tooltipText="Use this field to input any additional guidelines or focus areas for the AI when crafting questions. For example, you can specify the industry domain relevant to the job position, or highlight particular aspects of the job description that should be emphasized. This information helps tailor the AI's questioning to better align with the job's specific requirements and the candidate's background. It ensures a more targeted and relevant assessment by directing the AI to consider these special instructions alongside the skills and experience levels mentioned in the candidate's form."
							/>
							<div className="flex flex-row gap-3 ">
								<Button variant="contained" disabled={isMakingNewEvalRequest} type="button">
									{isMakingNewEvalRequest ? "Evaluating..." : "GET EVALUATION"}
								</Button>
								{showAntiCheatingScoreField && (
									<CheatingScoreButton interviewReportId={interviewReportId} />
								)}
							</div>
						</Stack>
					</form>
				</FormProvider>
			</CardContent>
		</Card>
	);
};

function CheatingScoreButton({ interviewReportId }) {
	const [newScore, { isLoading }] = useCreateNewCheatingScoreMutation();
	async function getCheatingScoreForReport() {
		try {
			const res = await newScore(interviewReportId).unwrap();
			toast.success("Cheating score generated");
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<Button
			variant="contained"
			onClick={(e) => {
				getCheatingScoreForReport();
			}}
			type="button"
			disabled={isLoading}
		>
			{isLoading ? "Creating" : "Create"} Cheating Score
		</Button>
	);
}
