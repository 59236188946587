import axios from "axios";

// TODO: Use this for every API call
class ApiClient {
	constructor(config = {}) {
		this.retryConfig = {
			maxRetries: 3,
			initialRetryDelay: 1000,
			maxRetryDelay: 5000,
			timeout: 30000,
			shouldRetry: null,
			...config,
		};

		this.instance = axios.create({
			timeout: this.retryConfig.timeout,
		});

		this.setupInterceptors();
	}

	setupInterceptors() {
		this.instance.interceptors.response.use(
			(response) => response,
			async (error) => {
				const config = error.config || {};

				// Initialize retry count
				config._retry = config._retry || 0;

				// Check if we should retry the request
				if (this.shouldRetry(error) && config._retry < this.retryConfig.maxRetries) {
					config._retry++;

					// Calculate delay with exponential backoff
					const delay = Math.min(
						this.retryConfig.initialRetryDelay * Math.pow(2, config._retry - 1),
						this.retryConfig.maxRetryDelay,
					);

					// Wait for the calculated delay
					await new Promise((resolve) => setTimeout(resolve, delay));

					// Retry the request
					return this.instance(config);
				}

				return Promise.reject(error);
			},
		);
	}

	shouldRetry(error) {
		if (this.retryConfig.shouldRetry) {
			return this.retryConfig.shouldRetry(error);
		}

		// Default retry conditions
		return (
			!error.response || // Network errors
			error.code === "ECONNABORTED" || // Timeout
			[408, 429, 500, 502, 503, 504].includes(error.response?.status) // Retryable status codes
		);
	}
}

// Create and export a default instance
export const apiClient = new ApiClient();

// Export the class for custom instances
export { ApiClient };
