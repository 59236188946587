import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Countdown } from "./countdown";
import { InterviewRescheduler } from "./interview-rescheduler";
import { useMemo } from "react";

export const PreInterviewDetailsDisplay = ({ details }) => {
	const {
		interviewReport: {
			schedule,
			cancelled,
			reasonForCancellation,
			firstName,
			lastName,
			allowRescheduleByCandidate,
			maxRescheduleDateForCandidate,
			interviewTimeZone,
		},
		openingTitle,
		orgName,
		supportName,
		supportPhone,
	} = details;

	// Check for the show-rescheduler parameter
	const showRescheduler = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("show-rescheduler") === "true";
	}, []);

	// Format date using Intl
	const formattedDateTime = schedule
		? new Intl.DateTimeFormat("en-US", {
				weekday: "long",
				month: "long",
				day: "numeric",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			}).format(new Date(schedule))
		: "Date Not Specified";

	// Render the schedule information section
	const renderScheduleInfo = () => {
		if (cancelled) {
			return (
				<div className="bg-red-50 border border-red-200 p-3 rounded-md text-red-800">
					<h4 className="font-semibold">Interview Cancelled</h4>
					{reasonForCancellation && <p>Reason: {reasonForCancellation}</p>}
				</div>
			);
		}

		if (!schedule) return null;

		return (
			<>
				<div className="mb-4 p-3 bg-blue-50 rounded-md">
					<div className="flex items-center text-blue-800 mb-2">
						<AccessTimeIcon className="mr-2" />
						<span className="font-medium">{formattedDateTime}</span>
					</div>
					<Countdown targetTime={schedule} />
				</div>
				{showRescheduler ? (
					allowRescheduleByCandidate ? (
						<InterviewRescheduler
							schedule={schedule}
							maxRescheduleDateForCandidate={maxRescheduleDateForCandidate}
							interviewTimeZone={interviewTimeZone}
						/>
					) : (
						<div className="bg-red-50 border border-red-200 p-3 rounded-md text-red-800">
							<h4 className="font-semibold mb-2">Unable to Reschedule Online</h4>
							<p className="text-sm mb-2">
								The recruiter has disabled online rescheduling for this interview. If you need
								to reschedule, please contact:
							</p>
							<div className="ml-2 text-sm">
								<p>
									<span className="font-medium">Recruiter:</span> {supportName}
								</p>
								<p>
									<span className="font-medium">Phone:</span> {supportPhone}
								</p>
							</div>
							<p className="text-xs mt-2 text-red-600">
								Please contact well in advance if you need to reschedule.
							</p>
						</div>
					)
				) : null}
			</>
		);
	};

	return (
		<div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 border border-gray-200">
			<div className="mb-4">
				<h2 className="text-2xl font-bold text-gray-800 flex items-center">
					<WorkIcon className="mr-2 text-blue-600" />
					{openingTitle}
				</h2>
				<p className="text-lg text-gray-600">{orgName}</p>
			</div>

			<div className="mb-4">
				<h3 className="text-xl font-semibold text-gray-700 flex items-center">
					<PersonIcon className="mr-2 text-blue-600" />
					{firstName} {lastName}
				</h3>
			</div>

			{renderScheduleInfo()}
		</div>
	);
};
