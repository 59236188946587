import { Alert, Button, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import React, { useState } from "react";
import InterviewScheduleComponent from "../../bulkSchedule/InterviewScheduleComponent";

const ScheduleRenderer = ({ params, openingData }) => {
	let interviewReport = params.row;
	const [modalState, setModalState] = React.useState();
	const [scheduleType, setScheduleType] = useState(-1);

	const closeScheduleModal = () => {
		setModalState(false);
	};

	return (
		<div className="flex items-center justify-center w-full h-full flex-wrap gap-1">
			{modalState ? (
				<InterviewScheduleComponent
					open={modalState}
					scheduleType={scheduleType}
					onClose={closeScheduleModal}
					interviewReport={interviewReport}
				/>
			) : (
				""
			)}
			{interviewReport?.interviewCompleted ? (
				"Interview Completed"
			) : (
				<>
					{interviewReport.hasBeenRescheduledByCandidate && (
						<Alert severity="info">Rescheduled by Candidate</Alert>
					)}
					<Tooltip
						title={interviewReport?.schedule ? "Reschedule Interview" : "Schedule Interview"}
						arrow
					>
						<Button
							className="normal-case shadow-none text-white"
							variant="contained"
							startIcon={<i className="fas scale-75 fa-calendar-check"></i>}
							size="small"
							onClick={() => {
								setModalState(true);
								interviewReport?.schedule
									? setScheduleType(1) // 1 -> Reschedule Interview
									: setScheduleType(0); // 0 -> Schedule Interview
							}}
							// disabled={saving}
							sx={{
								backgroundColor: interviewReport?.schedule ? "#F59E0B" : "#10B981",
								":hover": {
									backgroundColor: interviewReport?.schedule ? "#B45309" : "#047857",
								},
							}}
						>
							{interviewReport?.schedule ? "Reschedule" : "Schedule"}
						</Button>
					</Tooltip>
					<Tooltip title="Copy Interview Link" arrow>
						<Button
							className="shadow-none normal-case bg-blue-500 hover:bg-blue-600"
							variant="contained"
							startIcon={<i className="fas scale-75 fa-link"></i>}
							size="small"
							onClick={() => {
								const urlToShare = interviewReport?.resumeToken
									? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
									: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
								navigator.clipboard.writeText(urlToShare);
								toast.success("Link copied to clipboard");
							}}
						>
							Share Link
						</Button>
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default ScheduleRenderer;
