import React, { useMemo, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import logo from "../../assets/zinterview-logo-black.png";

const FaceDetectionFrames = ({ _id, faceDetectionFrames }) => {
	const [faceIndex, setFaceIndex] = useState(0);

	const categorizedFrames = useMemo(() => {
		const noFace = [];
		const singleFace = [];
		const multipleFaces = [];

		faceDetectionFrames.forEach((fileName) => {
			if (fileName.includes("_0")) noFace.push(fileName);
			else if (fileName.includes("_1")) singleFace.push(fileName);
			else multipleFaces.push(fileName);
		});

		return { noFace, singleFace, multipleFaces };
	}, [faceDetectionFrames]);

	const getLocalTimestamp = useCallback((fileName) => {
		const match = fileName.match(/^(\d{4}-\d{2}-\d{2}T\d{2}-\d{2}-\d{2}-\d{3}Z)/);
		if (!match) return "Invalid Date";
		const formattedTimestamp = match[0].replace(/T(\d{2})-(\d{2})-(\d{2})-(\d{3})Z/, "T$1:$2:$3.$4Z");
		const date = new Date(formattedTimestamp);
		if (isNaN(date.getTime())) {
			return "Invalid Date";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: true,
		}).format(date);
	}, []);

	const renderGallerySection = (title, files) => {
		return (
			<Box className="mb-6">
				{files?.length > 0 ? (
					<Box className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-4">
						{files?.map((file, index) => {
							const imageUrl = `https://zinterview-b1.s3.ap-southeast-1.amazonaws.com/face-detection-frames/${_id}/${file}`;

							return (
								<Box
									key={`${file}-${index}`}
									className="relative bg-gray-100"
									sx={{
										height: "150px",
										width: "100%",
										aspectRatio: "16/9",
										boxShadow: 2,
										borderRadius: "5px",
										overflow: "hidden",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<img
										loading="lazy"
										src={imageUrl}
										alt={file}
										onError={(e) => {
											e.target.onerror = null;
											e.target.src = logo;
										}}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
										}}
									/>
									<Box
										sx={{
											position: "absolute",
											bottom: "8px",
											right: "8px",
											background: "rgba(255, 255, 255, 0.3)",
											borderRadius: "5px",
											padding: "2px 6px",
											backdropFilter: "blur(5px)",
											fontSize: "0.75rem",
											color: "#fff",
											zIndex: 10,
										}}
									>
										{getLocalTimestamp(file)}
									</Box>
								</Box>
							);
						})}
					</Box>
				) : (
					<Typography variant="body2" className="text-gray-500">
						No frames available
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<Box>
			<Box className="mb-8 border-b-0 text-sm flex justify-start align-center">
				<Typography
					className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm ${
						faceIndex === 0
							? `text-purple-800 font-semibold border-purple-800`
							: "border-gray-100"
					}`}
					onClick={() => setFaceIndex(0)}
				>
					Single Face Detected
				</Typography>
				<Typography
					className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm ${
						faceIndex === 1
							? `text-purple-800 font-semibold border-purple-800`
							: "border-gray-100"
					}`}
					onClick={() => setFaceIndex(1)}
				>
					Multiple Faces Detected
				</Typography>
				<Typography
					className={`bg-white inline-block py-2 px-4 text-gray-800 hover:text-purple-800 cursor-pointer border-solid border-0 border-b-2 text-sm ${
						faceIndex === 2
							? `text-purple-800 font-semibold border-purple-800`
							: "border-gray-100"
					}`}
					onClick={() => setFaceIndex(2)}
				>
					No Face Detected
				</Typography>
			</Box>
			{faceIndex === 0
				? renderGallerySection("Single Face Detected", categorizedFrames?.singleFace)
				: faceIndex === 1
					? renderGallerySection("Multiple Faces Detected", categorizedFrames?.multipleFaces)
					: renderGallerySection("No Face Detected", categorizedFrames?.noFace)}
		</Box>
	);
};

export default FaceDetectionFrames;
