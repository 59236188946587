import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LogRocket from "logrocket";

export const LogRocketInterviewSessionController = ({ interviewReportData }) => {
	const location = useLocation();

	useEffect(() => {
		const currentPath = location.pathname;

		const isProduction = window.location.hostname === "app.zinterview.ai";

		if (isProduction && currentPath.startsWith("/interview")) {
			LogRocket.init("vrxjd4/interview-processes");

			if (interviewReportData) {
				LogRocket.identify(interviewReportData._id, {
					name: `${interviewReportData.firstName} ${interviewReportData.lastName}`,
					email: interviewReportData.email,
				});
			}
		}
	}, [location, interviewReportData]);

	return null;
};
