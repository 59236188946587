import React from "react";
import { Box, Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Divider } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessIcon from "@mui/icons-material/Business";
import AddIcon from "@mui/icons-material/Add";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import WorkIcon from "@mui/icons-material/Work";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/auth/authSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import { Key, RestartAlt } from "@mui/icons-material";
import { toast } from "react-toastify";
import { generateApiKey, rotateApiKey } from "../../utilities/authApi";
import TimerIcon from "@mui/icons-material/Timer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { disconnectUserFromRoom } from "./RealTimeNotificationSocket";
import AssessmentIcon from "@mui/icons-material/Assessment";

const SideDrawer = ({ drawerOpen, toggleDrawer }) => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const generateKey = async () => {
		try {
			generateApiKey();
		} catch (error) {
			toast.error("Error generating api key");
		}
	};

	const rotateKey = async () => {
		try {
			rotateApiKey();
		} catch (error) {
			toast.error("Error rotating api key");
		}
	};

	const handleLogout = () => {
		user.role === "1096" && disconnectUserFromRoom(user?.organizationId);
		dispatch(logoutUser());
	};

	const drawerContent = (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}>
				<Typography
					variant="body1"
					sx={{ color: "grey.800", cursor: "pointer" }}
					className="no-underline hover:underline"
				>
					<i className="fas fa-user-circle" style={{ color: "grey", marginRight: "8px" }}></i>
					Welcome, {user?.organizationName && user?.organizationName}
				</Typography>
			</Box>
			<Divider />
			<List>
				{user?.role === "1096" && (
					<>
						<ListItem button onClick={() => navigate("/superadmin")}>
							<ListItemIcon>
								<AdminPanelSettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Home" />
						</ListItem>
						<ListItem button onClick={() => navigate("/admin")}>
							<ListItemIcon>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText primary="Your Admin Panel" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/organizations")}>
							<ListItemIcon>
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText primary="Organizations" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/create")}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary="Create Organisation" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/recentInterviews")}>
							<ListItemIcon>
								<EventNoteIcon />
							</ListItemIcon>
							<ListItemText primary="Recent Interviews" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/onGoing")}>
							<ListItemIcon>
								<TimerIcon />
							</ListItemIcon>
							<ListItemText primary="Ongoing Interviews" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/dropInterviews")}>
							<ListItemIcon>
								<PersonRemoveIcon />
							</ListItemIcon>
							<ListItemText primary="Drop Interviews" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/plans")}>
							<ListItemIcon>
								<AttachMoneyIcon />
							</ListItemIcon>
							<ListItemText primary="Plans" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/create-plans")}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary="Create Plans" />
						</ListItem>
						<ListItem button onClick={() => navigate("/admin/question-bank")}>
							<ListItemIcon>
								<WorkIcon />
							</ListItemIcon>
							<ListItemText primary="Question Bank" />
						</ListItem>
						<ListItem button onClick={() => navigate("/superadmin/setting")}>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Setting" />
						</ListItem>
						<ListItem button onClick={() => navigate(`/admin/interviews`)}>
							<ListItemIcon>
								<AssessmentIcon />
							</ListItemIcon>
							<ListItemText primary="Interviews" />
						</ListItem>
						{/* <ListItem button onClick={() => navigate("/superadmin/changelog-form")}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<ListItemText primary="Changelog" />
						</ListItem> */}
					</>
				)}
				{user?.role === "1024" && (
					<>
						<ListItem button onClick={() => navigate("/admin")}>
							<ListItemIcon>
								<AdminPanelSettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Admin" />
						</ListItem>
						<ListItem button onClick={() => navigate("/admin/question-bank")}>
							<ListItemIcon>
								<WorkIcon />
							</ListItemIcon>
							<ListItemText primary="Question Bank" />
						</ListItem>
						<ListItem button onClick={() => navigate(`/admin/interviews`)}>
							<ListItemIcon>
								<AssessmentIcon />
							</ListItemIcon>
							<ListItemText primary="Interviews" />
						</ListItem>
						{user.isPricingFeatureActive && (
							<ListItem button onClick={() => navigate(`/admin/billing/`)}>
								<ListItemIcon>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText primary="Billing and Account" />
							</ListItem>
						)}
					</>
				)}
				{/* {["prabhat.kumar@riktamtech.com", "prabhat262111@gmail.com"].indexOf(user?.email) !== -1 && ( */}
				<>
					<ListItem button onClick={generateKey}>
						<ListItemIcon>
							<Key fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Generate API Key" />
					</ListItem>
					<ListItem button onClick={rotateKey}>
						<ListItemIcon>
							<RestartAlt fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Rotate API Key" />
					</ListItem>
				</>
				{/* )} */}

				<ListItem button onClick={handleLogout}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Log Out" />
				</ListItem>
			</List>
		</Box>
	);

	return (
		<Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
			{drawerContent}
		</Drawer>
	);
};

export default SideDrawer;
