import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LogRocket from "logrocket";
import { useSelector } from "react-redux";

// Define excluded routes where LogRocket shouldn't run
const EXCLUDED_ROUTES = ["/login", "/interview", "/admin/proctor", "/superadmin"];

export const LogRocketManagementController = () => {
	const location = useLocation();
	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		const currentPath = location.pathname;

		const isExcluded = EXCLUDED_ROUTES.some((route) => currentPath.startsWith(route));
		const isProduction = window.location.hostname === "app.zinterview.ai";

		if (isProduction && user && user.role !== "1096" && !isExcluded) {
			LogRocket.init("bvszfu/zi-admin-portal");
			LogRocket.identify(user.userId, {
				organizationName: user.organizationName,
				organizationId: user.organizationId,
				name: user.userName,
				email: user.email,
			});
		}
	}, [location]);

	return null;
};
