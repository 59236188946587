import axios from "./axiosInstance";

const getChangelogs = async (version = "initial", limit = 2) => {
	try {
		const response = await axios.post(`/api/utility/get-changelogs`, {
			version,
			limit,
		});
		return response?.data;
	} catch (error) {
		throw error;
	}
};

export { getChangelogs };
