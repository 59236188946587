import { checkIf48HoursPassed } from "../../../../utilities/utilityFunctions";

const intlInstance = new Intl.NumberFormat("en-IN", {
	maximumFractionDigits: 3,
});

export const getScore = (row) => {
	let interviewReport = row;
	let candidateScore = null;

	try {
		const { evaluation } = interviewReport;
		let parsedJSON = JSON.parse(evaluation);
		const { verdict, evaluation_report } = parsedJSON;
		const score = verdict?.score;
		const overall_score = evaluation_report?.overall_score;
		const overall_score_in_percentage = evaluation_report?.overall_score_in_percentage;

		if (score >= 0) {
			candidateScore = intlInstance.format(+score);
		}
		if (overall_score >= 0) {
			candidateScore = intlInstance.format(+overall_score) + "/5";

			candidateScore = Math.round(+overall_score * 20);
		}
		if (overall_score_in_percentage >= 0) {
			candidateScore = intlInstance.format(+overall_score_in_percentage);
		}
		return candidateScore;
	} catch (error) {
		return null;
	}
};

export const getTrustScore = (interviewReport) => {
	return interviewReport?.trustScore
		? interviewReport?.trustScore !== 0
			? interviewReport?.trustScore
			: 0
		: null;
};

export const getCheatingScore = (report) => {
	return report?.cheatingScore?.likelihood_of_cheating;
};

export const nameColumnComparator = (row, filterItem, operator, key = null) => {
	const firstName = row.firstName?.toLowerCase() || "";
	const lastName = row.lastName?.toLowerCase() || "";
	const fullName = `${firstName} ${lastName}`;
	const email = row.email?.toLowerCase() || "";
	const phoneNumber = row.phoneNumber?.toLowerCase() || "";
	const openingTitle = row?.openingData?.title?.toLowerCase() || "";
	const filterValue = filterItem?.value?.toLowerCase();

	switch (operator) {
		case "contains":
			return (
				fullName.includes(filterValue) ||
				email.includes(filterValue) ||
				phoneNumber.includes(filterValue) ||
				openingTitle.includes(filterValue)
			);
		case "startsWith":
			return (
				fullName.startsWith(filterValue) ||
				email.startsWith(filterValue) ||
				phoneNumber.startsWith(filterValue) ||
				openingTitle.includes(filterValue)
			);
		case "equals":
			return (
				fullName === filterValue ||
				email === filterValue ||
				phoneNumber === filterValue ||
				openingTitle == filterValue
			);
		case "empty":
			return !fullName && !email && !phoneNumber && !openingTitle;
		case "notEmpty":
			return fullName || email || phoneNumber || openingTitle;
		case "endsWith":
			return (
				fullName.endsWith(filterValue) ||
				email.endsWith(filterValue) ||
				phoneNumber.endsWith(filterValue) ||
				openingTitle.endsWith(filterValue)
			);
		default:
			return false;
	}
};

export const stringComparator = (row, filterItem, operator, key) => {
	try {
		if (!key || !row || !row.hasOwnProperty(key)) {
			return false;
		}
		let value = row[key].toLowerCase();

		switch (operator) {
			case "contains":
				return value.includes(filterItem.toLowerCase());
			case "startsWith":
				return value.startsWith(filterItem.toLowerCase());
			case "equals":
				return value === filterItem.toLowerCase();
			case "empty":
				return !value;
			case "notEmpty":
				return value;
			case "endsWith":
				return value.endsWith(filterItem.toLowerCase());
			case "is":
				return value === filterItem.toLowerCase();
			default:
				return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const getInterviewDate = (row) => {
	return new Date(row.createdAt);
};

export const interviewStatusComparator = (row, value, openingData) => {
	let interviewStatus = getInterviewStatus({ ...row, opening: openingData });
	switch (value) {
		case "scheduled":
			return interviewStatus.toLowerCase().includes("scheduled");
		case "completed":
			return interviewStatus.toLowerCase().includes("completed");
		case "cancelled":
			return interviewStatus.toLowerCase().includes("cancelled");
		case "dropped":
			return interviewStatus.toLowerCase().includes("dropped");
		case "ongoing":
			return interviewStatus.toLowerCase().includes("ongoing");
		case "new":
			return interviewStatus.toLowerCase().includes("new");
		default:
			return false;
	}
};

export const compareInterviewStatus = (value, filterValue) => {
	switch (filterValue.toLowerCase()) {
		case "scheduled":
			return value.toLowerCase().includes("scheduled");
		case "completed":
			return value.toLowerCase().includes("completed");
		case "cancelled":
			return value.toLowerCase().includes("cancelled");
		case "dropped":
			return value.toLowerCase().includes("dropped");
		case "ongoing":
			return value.toLowerCase().includes("ongoing");
		case "new":
			return value.toLowerCase().includes("new");
		default:
			return false;
	}
};

export const getResumeLink = (row) => {
	if (!row.resumeFileNameInS3) {
		return "";
	}
	const resumeFileName = row.resumeFileNameInS3;
	const hasValidExtension = /\.(pdf|doc|docx)$/i.test(resumeFileName);
	const resumeUrl = hasValidExtension ? resumeFileName : `${resumeFileName}.pdf`;

	const url = resumeUrl.endsWith(".pdf")
		? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
		: `${window.location.origin}/view/${resumeUrl}`;
	return url;
};

export const getAzurePronunciationResult = (row) => {
	if (!row.azurePronunciationResult) {
		return null;
	}
	try {
		const parsedResult = (() => {
			try {
				return JSON.parse(row.azurePronunciationResult || "{}");
			} catch (error) {
				console.error("Error parsing JSON:", error);
				return {};
			}
		})();
		return (
			parsedResult?.pronunciationResult?.privPronJson?.PronunciationAssessment?.PronScore ??
			parsedResult?.PronunciationAssessment?.PronScore ??
			null
		);
	} catch (error) {
		return null;
	}
};

export const getRecordingLink = (row) => {
	if (row.concatenationId) {
		return `https://procturemeet.s3.ap-southeast-1.amazonaws.com/recordings/${row?._id}/composited-video/${row?.concatenationId}.mp4`;
	} else {
		return null;
	}
};

export const getInterviewStatus = (interviewReport) => {
	const interviewStatus = interviewReport?.interviewCompleted
		? interviewReport?.clickedExitInterview
			? "Candidate Terminated Prematurely"
			: "Interview Completed"
		: interviewReport?.cancelled
			? "Interview Cancelled"
			: interviewReport?.schedule
				? "Scheduled"
				: interviewReport?.browserClosed
					? checkIf48HoursPassed(interviewReport?.lastActiveTimestamp)
						? "Dropped (Inactive for > 48 hours)"
						: "Dropped (Browser Closed)"
					: interviewReport?.opening?.proctoring
						? interviewReport?.meetingId
							? interviewReport?.isLatestMeetingIdInvalid
								? "Previous Meeting Ended"
								: "Ongoing"
							: interviewReport?.createdByAdmin
								? checkIf48HoursPassed(interviewReport?.createdAt)
									? "New (Added by Admin - Inactive for > 48 hours)"
									: "New (Added by Admin)"
								: "Dropped (Proctored - Unable to Proceed)"
						: interviewReport?.activeSession
							? "Ongoing"
							: interviewReport?.disconnected
								? checkIf48HoursPassed(interviewReport?.lastActiveTimestamp)
									? "Dropped (Disconnected - Inactive for > 48 hours)"
									: "Dropped (Disconnected)"
								: interviewReport?.createdByAdmin
									? checkIf48HoursPassed(interviewReport?.createdAt)
										? "New (Added by Admin - Inactive for > 48 hours)"
										: "New (Added by Admin)"
									: "Dropped (Unproctored - Unable to Proceed)";
	return interviewStatus;
};

export const getCustomFieldComparator = (value, filterItem, operator, id) => {
	value = String(value || "").toLowerCase();
	try {
		switch (operator) {
			case "contains":
				return value.includes(filterItem.value.toLowerCase());
			case "startsWith":
				return value.startsWith(filterItem.value.toLowerCase());
			case "equals":
				return value === filterItem.value.toLowerCase();
			case "empty":
				return !value;
			case "notEmpty":
				return value;
			case "endsWith":
				return value.endsWith(filterItem.value.toLowerCase());
			case "is":
				return value === filterItem.value.toLowerCase();
			default:
				return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const getValuesForCustomFields = (row, id, type, openingData) => {
	try {
		if (row.customFieldsData) {
			let value = row.customFieldsData[id];
			type = null;
			let customFields = openingData?.customFields;
			let customField = customFields?.find((field) => field._id === id);
			if (customField) {
				type = customField.type;
			}
			if (!value) {
				let defaultValue = "";
				defaultValue = customField?.config?.defaultValue || "";
				return defaultValue;
			}
			if (type === 1) {
				let option = openingData?.customFields?.find((field) => field._id === id);
				if (option) {
					let dropDownValue = option.config?.dropDownValues?.find((val) => val.id === value);
					return dropDownValue?.value || "";
				}
				return "";
			}
			return value;
		}
		return "";
	} catch (error) {
		console.log(error);
		return "";
	}
};
