import React, { useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
const CheatingScoreRenderer = ({ params }) => {
	const { row: report } = params;
	const { likelihood_of_cheating = "-", summary = "" } = report?.cheatingScore ?? {};
	return (
		<div className="flex justify-center items-center h-full">
			<FlagComponent score={likelihood_of_cheating} summary={summary} />
		</div>
	);
};

const FlagComponent = ({ score, summary = "" }) => {
	const [color, finalSummary] = useMemo(() => {
		return getFinalSummaryTextForACS(score, summary);
	}, [score, summary]);
	return (
		<Tooltip title={score === "-" ? "Cheating Score Unavailable" : finalSummary}>
			<FlagIcon className={clsx(color)} />
		</Tooltip>
	);
};

export default CheatingScoreRenderer;

export const getFinalSummaryTextForACS = (score, summary) => {
	let color = "text-gray-400";
	let note = "";
	let likelihoodMeter = "";
	if (score < 40) {
		color = "text-green-400";
		note = "Note: Review manually to rule out false negatives.";
		likelihoodMeter = "LOW";
	} else if (score >= 40 && score < 60) {
		color = "text-orange-400";
		note = "Note: Review manually to rule out false positives.";
		likelihoodMeter = "MEDIUM";
	} else if (score >= 60) {
		color = "text-red-600";
		note = "Note: Review manually to rule out false positives.";
		likelihoodMeter = "HIGH";
	}
	const finalSummary = (
		<>
			<h4>LIKELIHOOD: {likelihoodMeter}</h4>
			<p>{summary}</p>
			<br />
			<strong>{note}</strong>
		</>
	);
	return [color, finalSummary];
};
