import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Dialog, DialogTitle, Button, Typography, Chip, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/zinterview-logo-white.png";
import SideDrawer from "../pages/SuperAdmin/SideDrawer";
import { getOrganizationName } from "../utilities/organizationApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NotificationInfoPage from "./SuperAdmin/NotificationInfoPage";
import { getChangelogs } from "../utilities/utilityApi";
import { clientLogger } from "../utilities/loggingApi";

function Navbar() {
	const { user } = useSelector((state) => state.auth);
	const { organizationId } = useParams() || "";
	const [organizationName, setOrganizationName] = useState("Riktam");
	const [drawerOpen, setDrawerOpen] = useState(false);

	const navBarId = user?.role === "1024" ? "nav-bar" : "super-admin-nav-bar";

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [changelogData, setChangelogData] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [lastVersion, setLastVersion] = useState(null);
	const [loadedAllChangelogs, setLoadedAllChangelogs] = useState(false);
	const [latestVersion, setLatestVersion] = useState(null);
	const localStorageVersion = localStorage.getItem("LCV");
	const effectRan = useRef(false);
	const timeoutForLocalStorage = useRef(null);

	useEffect(() => {
		if (effectRan.current) return;

		(async function fetchInitialChangelog() {
			try {
				const response = await getChangelogs("initial", 2);
				setChangelogData(response?.changelogs);
				setHasMore(response?.hasMore);
				if (response?.changelogs?.length > 0) {
					setLatestVersion(response?.changelogs[0]?.version);
					setLastVersion(response?.changelogs[response?.changelogs?.length - 1]?.version);
				}

				if (
					response?.changelogs?.[0]?.version &&
					response?.changelogs[0]?.version !== localStorageVersion &&
					response?.changelogs[0]?.entries?.length > 0
				) {
					setIsPopupOpen(true);
					timeoutForLocalStorage.current = setTimeout(() => {
						localStorage.setItem("LCV", response?.changelogs[0]?.version);
					}, 2000);
				}
				effectRan.current = true;
			} catch (error) {
				console.error(error?.response?.data?.message);
				localStorageVersion && localStorage.removeItem("LCV");
				clientLogger(error.message, {
					level: "error",
					logStreamName: "Error_logs",
					error: error.toString(),
				});
			}
		})();

		return () => {
			timeoutForLocalStorage.current && clearTimeout(timeoutForLocalStorage.current);
		};
	}, []);

	const loadMoreChangelogs = async () => {
		if (!lastVersion) return;

		try {
			const response = await getChangelogs(lastVersion, 2);
			const newChangelogs = [...changelogData, ...response?.changelogs];
			setChangelogData(newChangelogs);
			setHasMore(response?.hasMore);

			if (response?.changelogs?.length > 0) {
				setLastVersion(response?.changelogs[response?.changelogs?.length - 1]?.version);
			}

			if (!response?.hasMore) {
				setLoadedAllChangelogs(true);
			}
		} catch (error) {
			console.error("Error fetching more changelogs:", error);
			clientLogger(error.message, {
				level: "error",
				logStreamName: "Error_logs",
				error: error.toString(),
			});
		}
	};

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	useEffect(() => {
		const getOrgName = async (organizationId) => {
			const response = await getOrganizationName(organizationId);
			setOrganizationName(response.data.organizationName);
		};
		if (organizationId) getOrgName(organizationId);
		else setOrganizationName(user?.organizationName || "");
	}, [organizationId]);

	const getChangeEmoji = (type) => {
		switch (type?.toLowerCase()) {
			case "new":
				return (
					<Tooltip title="New Feature" arrow placement="left">
						<i className="fas fa-lightbulb text-blue-500 w-[16px] text-center"></i>
					</Tooltip>
				);
			case "fix":
				return (
					<Tooltip title="Bug fix" arrow placement="left">
						<i className="fas fa-bug text-green-500 w-[16px] text-center"></i>
					</Tooltip>
				);
			case "deprecated":
				return (
					<Tooltip title="Deprecated" arrow placement="left">
						<i className="fas fa-exclamation-triangle text-yellow-500 w-[16px] text-center"></i>
					</Tooltip>
				);
			default:
				return <i className="fas fa-plus-circle text-purple-500 w-[16px] text-center"></i>;
		}
	};

	const handleWhatsNewClick = () => {
		if (changelogData?.length > 0) {
			setIsPopupOpen(true);
		}
	};

	return (
		<Box>
			<Box
				className="fixed top-0 w-full bg-blue-500 p-4 flex justify-between items-center"
				id={navBarId}
				sx={{ zIndex: "1000" }}
			>
				<Box className="flex items-center w-full">
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
						<MenuIcon color="white" />
					</IconButton>
					<Box className="relative flex items-center justify-center w-full h-full flex-1">
						<img
							src={logo}
							alt="Zinterview Logo"
							className="w-44 absolute left-0 transform transition-transform duration-300 hover:scale-105"
						/>
					</Box>
					{changelogData?.length > 0 && (
						<Box className="relative inline-block text-left">
							<Button
								type="button"
								variant="contained"
								className="normal-case text-xs bg-blue-600 hover:bg-blue-700 text-white py-2 px-3 rounded-full transition-all duration-300 ease-in-out transform focus:outline-none shadow-none"
								onClick={handleWhatsNewClick}
							>
								What's New
							</Button>
						</Box>
					)}
				</Box>
				<SideDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
				{organizationId && (
					<Box className="ml-2 flex items-center gap-[1rem]">
						<Typography variant="p" className="text-white no-underline text-sm">
							<Chip label={`Logged in account of ${organizationName}`} color="primary" />
						</Typography>
					</Box>
				)}
				{user?.role === "1096" && <NotificationInfoPage />}
			</Box>

			{/* Changelog Popup */}
			<Dialog
				open={isPopupOpen}
				onClose={() => setIsPopupOpen(false)}
				maxWidth="sm"
				fullWidth
				sx={{
					"& .MuiDialog-paper": {
						borderRadius: "16px",
						background: "linear-gradient(to bottom right, #f5f7fa, #e6e9f0)",
						boxShadow: "0 10px 25px rgba(0,0,0,0.1)",
					},
				}}
			>
				<DialogTitle
					className="bg-blue-500 text-white"
					sx={{
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<span>What's New</span>
					<Tooltip title="Close">
						<IconButton onClick={() => setIsPopupOpen(false)}>
							<CloseIcon fontSize="small" className="text-white rounded-full" />
						</IconButton>
					</Tooltip>
				</DialogTitle>
				<Box className="p-4 max-h-[500px] overflow-y-auto">
					{changelogData.map((log, index) => (
						<Box
							key={index}
							className="mb-4 p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 ease-in-out"
						>
							<Typography
								variant="h6"
								className="text-blue-600 mb-2"
							>{`Version ${log.version} ${log.version === latestVersion ? "(latest)" : ""}`}</Typography>
							{log.entries.map((entry, i) => (
								<Box
									key={i}
									className="flex items-start mb-2 p-1 hover:bg-gray-50 rounded-md transition-colors duration-200"
								>
									<Box className="flex items-start mr-3 mt-1">
										{getChangeEmoji(entry.type)}
									</Box>
									<Typography className="text-gray-700 flex-1">{entry.text}</Typography>
								</Box>
							))}
						</Box>
					))}
					{hasMore && !loadedAllChangelogs ? (
						<Box className="flex justify-center mt-3">
							<Button
								onClick={loadMoreChangelogs}
								variant="contained"
								color="primary"
								className="rounded-full px-6 py-2 transition-all duration-300 ease-in-out transform hover:scale-105"
							>
								Load More
							</Button>
						</Box>
					) : (
						loadedAllChangelogs && (
							<Box className="text-center mt-3 text-gray-500 italic">
								That's all for now. You're all caught up.
							</Box>
						)
					)}
				</Box>
			</Dialog>
		</Box>
	);
}

export default Navbar;
