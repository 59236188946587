import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import { getFormattedDateTime3 } from "../../../utilities/utilityFunctions";
import { useEffect } from "react";

export const RescheduleOptions = ({
	allowRescheduleByCandidate,
	setAllowRescheduleByCandidate,
	selectedDate,
	selectedTimeZone,
	maxRescheduleDateForCandidate,
	setMaxRescheduleDateForCandidate,
}) => {
	// Ensure dates are consistent
	const minDate = new Date(selectedDate);

	// Calculate max date (30 days from selected date)
	const maxDate = new Date(selectedDate);
	maxDate.setDate(maxDate.getDate() + 30);

	// Effect to handle selectedDate changes
	useEffect(() => {
		if (!maxRescheduleDateForCandidate) return;

		const currentMaxDate = new Date(maxRescheduleDateForCandidate);

		// Check if current maxRescheduleDate is outside the new valid range
		if (currentMaxDate < minDate || currentMaxDate > maxDate) {
			setMaxRescheduleDateForCandidate(new Date(minDate));
		}
	}, [selectedDate]); // Dependency on selectedDate

	const handleDateChange = (dates) => {
		if (!dates.length) return;

		try {
			const newSelectedDate = new Date(dates[0]);

			// Validate against min date
			if (newSelectedDate < minDate) {
				setMaxRescheduleDateForCandidate(new Date(minDate));
				return;
			}

			// Validate against max date
			if (newSelectedDate > maxDate) {
				setMaxRescheduleDateForCandidate(new Date(maxDate));
				return;
			}

			setMaxRescheduleDateForCandidate(newSelectedDate);
		} catch (error) {
			console.error("Date selection error:", error);
			toast.error("Invalid date selection. Please try again.");
		}
	};

	return (
		<div>
			<h5 className="text-base font-semibold flex justify-between">
				Rescheduling Options
				<div className="relative group">
					<span className="text-blue-500 cursor-pointer text-sm">ⓘ How it works</span>
					<div className="hidden group-hover:block absolute right-0 w-64 p-2 bg-white border rounded-lg shadow-lg z-10 text-sm">
						<ul className="text-gray-600 space-y-1 list-inside">
							<li>
								If enabled, candidates can reschedule their interview once the link is shared
							</li>
							<li>The candidate will be shown the same calendar to pick a new time</li>
							<li>
								They can only select dates up to the date you choose in the right calendar
							</li>
							<li>This helps you control how far ahead candidates can reschedule</li>
						</ul>
					</div>
				</div>
			</h5>

			<label className="flex items-center space-x-2 cursor-pointer my-2">
				<input
					type="checkbox"
					checked={allowRescheduleByCandidate}
					onChange={() => setAllowRescheduleByCandidate(!allowRescheduleByCandidate)}
					className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
				/>
				<span>Allow candidate to reschedule</span>
			</label>

			{allowRescheduleByCandidate && (
				<div className="my-4 rounded-lg border border-gray-200 shadow-sm p-4">
					<p className="text-sm text-gray-600 mb-2">Set maximum reschedule date:</p>
					<Flatpickr
						value={maxRescheduleDateForCandidate}
						options={{
							dateFormat: "D M d Y",
							minDate,
							maxDate,
							inline: true,
							onChange: handleDateChange,
						}}
						style={{ display: "none" }}
					/>

					<div className="mt-3 text-sm">
						Candidate can reschedule between {getFormattedDateTime3(selectedDate)} and{" "}
						{getFormattedDateTime3(maxRescheduleDateForCandidate)}
					</div>
				</div>
			)}
		</div>
	);
};
