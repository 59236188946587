import {
	compareInterviewStatus,
	getInterviewStatus,
	getScore,
	getTrustScore,
	getValuesForCustomFields,
	interviewStatusComparator,
} from "../grid/columnRenderer/filters/valueGetters";

export const getFilteredRecordsFromCriteria = (records, criteria, openingData) => {
	if (!criteria || !records) {
		return records;
	}
	if (criteria.length === 0) {
		return records;
	}
	records = records.filter((record) => {
		let columnMap = getColumnValuesMap({ ...record, opening: openingData }, openingData.customFields);
		let parsed = parseCriteria(criteria, columnMap, openingData);
		return parsed;
	});

	return records;
};

export const getColumnValuesMap = (record, customField) => {
	let columnMap = {
		Name: `${record.firstName} ${record.lastName}`,
		Email: record.email,
		PhoneNumber: record.phoneNumber,
		Experience: record.experience,
		TrustScore: getTrustScore(record),
		Score: getScore(record),
		InterviewStatus: getInterviewStatus(record),
		InterviewDate: record.createdAt,
		ScheduleAsDate: record.schedule ? new Date(record.schedule) : null,
		CheatingScore: record?.cheatingScore?.likelihood_of_cheating ?? 0,
	};
	customField?.forEach((field) => {
		let val = getValuesForCustomFields(record, field._id, null, { customField: customField });
		columnMap[field._id] = val;
		columnMap[field.fieldName] = val;
	});

	return columnMap;
};

let formats = {
	experience: "number",
	score: "number",
	trustscore: "number",
	interviewdate: "date",
	createdbyadmin: "boolean",
	cheatingscore: "number",
};

const parseCriteria = (criteria = [], columnValuesMap = {}, openingData) => {
	if (!criteria || criteria.length === 0) {
		return true;
	}
	columnValuesMap = Object.keys(columnValuesMap).reduce((acc, key) => {
		acc[key.toLowerCase()] = columnValuesMap[key];
		return acc;
	}, {});
	let values = [];
	let criteriaString = "";

	if (criteria[0]?.type === "condition") {
		criteria.shift();
	}

	if (criteria[criteria.length - 1]?.type === "condition") {
		criteria.pop();
	}

	criteria.forEach((row, index) => {
		if (typeof row === "object") {
			if (row.type === "condition") {
				criteriaString += row.operator === "AND" ? values.push("&&") : values.push("||");
			} else if (row.type === "row") {
				let { column, operator, value } = row;
				if (!columnValuesMap.hasOwnProperty(column.toLowerCase())) {
					return;
				}
				if (!value) value = "";
				if (column === "" || operator === "" || !column || !operator) {
					return;
				}
				let format = formats[column.toLowerCase()] ? formats[column.toLowerCase()] : "string";
				let ans = evaluateLhsRhs(columnValuesMap[column.toLowerCase()], value, operator, format);
				if (column.toLowerCase() === "interviewstatus") {
					switch (value) {
						case "Candidate Terminated Prematurely":
							value = "cancelled";
							break;
						case "Interview Completed":
							value = "completed";
							break;
						case "Interview Scheduled":
							value = "scheduled";
							break;
						case "Interview Not Started":
							value = "new";
							break;
						case "Interview Cancelled":
							value = "cancelled";
							break;
						default:
							break;
					}
					ans = compareInterviewStatus(columnValuesMap[column.toLowerCase()], value);
				}
				values.push(ans ? "true" : "false");
			}
		}
	});
	return parseValues(values);
};

const parseValues = (values = []) => {
	let valueStack = [];
	let operatorStack = [];
	values.forEach((value, index) => {
		if (value === "&&" || value === "||") {
			operatorStack.push(value);
			if (valueStack.length >= 2) {
				let rhs = valueStack.pop() === "true";
				let lhs = valueStack.pop() === "true";
				let operator = operatorStack.pop();
				let ans = operator === "&&" ? (lhs && rhs ? "true" : "false") : lhs || rhs ? "true" : "false";
				valueStack.push(ans);
			}
		} else {
			valueStack.push(value);
			if (operatorStack.length > 0 && valueStack.length >= 2) {
				let rhs = valueStack.pop() === "true";
				let lhs = valueStack.pop() === "true";
				let operator = operatorStack.pop();
				let ans = operator === "&&" ? (lhs && rhs ? "true" : "false") : lhs || rhs ? "true" : "false";
				valueStack.push(ans);
			}
		}
	});
	return valueStack[0] ? valueStack[0] === "true" : false;
};

const evaluateLhsRhs = (lhs = "", rhs = "", operator = "", format = "string") => {
	try {
		if (format == "date") {
			lhs = lhs == "" || lhs == null || lhs == undefined ? "" : new Date(lhs).toDateString();
			if (operator !== "empty" && operator !== "not empty")
				rhs = rhs == "" || rhs == null || rhs == undefined ? "" : new Date(rhs).toDateString();
		}
		if (format == "number") {
			lhs = parseInt(lhs);
			if (operator !== "empty" && operator !== "not empty") rhs = parseInt(rhs);
		}
		if (format == "boolean") {
			lhs = lhs === "true" || lhs === true;
			operator = operator === "true" || operator === true;
			return lhs === operator;
		}
		switch (operator) {
			case ">":
				return lhs > rhs;
			case "<":
				return lhs < rhs;
			case "=":
				return lhs === rhs;
			case "!=":
				return lhs !== rhs;
			case "contains":
				return lhs.includes(rhs);
			case "not contains":
				return !lhs.includes(rhs);
			case "starts with":
				return lhs.startsWith(rhs);
			case "ends with":
				return lhs.endsWith(rhs);
			case "empty":
				return lhs === "" || lhs === null || lhs === undefined;
			case "not empty":
				return lhs !== "" && lhs !== null && lhs !== undefined;
			case "today":
				return lhs === new Date().toDateString();
			case "tomorrow":
				return lhs === new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString();
			default:
				return false;
		}
	} catch (error) {
		return false;
	}
};
